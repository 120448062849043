<template>
  <c-loading :loading="isloading">
    <div class="hotel-scenic-list">
      <navigation-bar :type="11" arrCityName="" @changeSearch="changeSearch" :originCName="pageCity.cityName" @search="inputSearch"></navigation-bar>
      <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit" :class="isIos?'mescroll':'mescrollAndriod'">
        <!-- <div class="radiusWrap">
          <div class="radius"></div>
        </div> -->
        <div class="contain">
          <div class="item" v-for="(item, index) in dataList" :key="index" @click="jumptoDetail(item)">
            <hotel-scenic-card :dataItem="item"></hotel-scenic-card>
          </div>
          <placeholder v-if="dataList.length === 0"></placeholder>
        </div>

      </mescroll-vue>
    </div>
  </c-loading>
</template>
<script>
import hotelScenicCard from "./components/hotel-scenic-card";
import NavigationBar from "./components/navigation-bar";
// import { px2rem } from "./mixins";
import * as LocalData from "@/service/localData";
// import localStorage from "@/utils/localStorage";
import Placeholder from "@/components/placeholder";
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";
// import _ from "loadsh";
import service from "@/service";
import get from "@/utils/get";
import MescrollVue from "mescroll.js/mescroll.vue";
// import env from "@/utils/env";

export default {
  name: "hotel-scenic-list",
  // mixins: [px2rem],
  components: {
    hotelScenicCard,
    NavigationBar,
    MescrollVue,
    Placeholder,
  },
  data() {
    return {
      keyWords: "", //搜索关键词
      mescroll: null, // mescroll实例对象
      isloading: false,
      dataList: [
        // {
        //   productId: "",
        //   productName: "希尔曼湖景影院酒店双人出行",
        //   tags: [
        //     { name: "五星酒店", colour: "#34384B" },
        //     { name: "五星酒店", colour: "#34384B" },
        //     { name: "五星酒店", colour: "#34384B" },
        //   ],
        //   productTags: [
        //     { name: "过期退", colour: "#0078FF" },
        //     { name: "过期退", colour: "#0078FF" },
        //   ],
        //   itemList: [
        //     {
        //       itemType: "",
        //       itemId: "",
        //       itemName: "",
        //       itemDesc: "迪士尼乐园 · 豪华大床房1晚",
        //       icon: "",
        //     },
        //     {
        //       itemType: "",
        //       itemId: "",
        //       itemName: "",
        //       itemDesc: "迪士尼乐园 · 豪华大床房1晚",
        //       icon: "",
        //     },
        //   ],
        //   price: 198,
        //   level: "",
        //   productImageUrl: "https://static.runoob.com/images/demo/demo2.jpg",
        // },
      ],
      mescrollDown: {
        callback: this.downCallback,
      },
      mescrollUp: {
        callback: this.upCallback,
        page: {
          num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 20, //每页数据条数,默认10
        },
        auto: false,
      },
      pageCity:
        LocalData.getCity().default == true
          ? LocalData.getOriginCity()
          : LocalData.getCity(),
    };
  },
  beforeRouteEnter(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next((vm) => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.mescroll && vm.$refs.mescroll.beforeRouteEnter();
    });
  },
  beforeRouteLeave(to, from, next) {
    // 如果没有配置顶部按钮或isBounce,则beforeRouteLeave不用写
    // 记录列表滚动的位置,隐藏顶部按钮和isBounce的配置
    this.$refs.mescroll && this.$refs.mescroll.beforeRouteLeave();
    next();
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
    }
  },
  computed: {
    isIos() {
      if (env.os == "ios") {
        return 1;
      } else {
        return 0;
      }
    },
  },
  methods: {
    //跳转详情
    jumptoDetail(item) {
      const query = {
        productId: item.productId,
      };
      // const page = { path: "./hotel-scenic", query };
      // // console.log("page", page, item);
      // this.$router.push(page);
      const page = {
        url: "/hotel-scenic",
        name: "hotel-scenic",
        query: query,
        params: {},
      };
      this.$page.push(page);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    //下拉刷新
    downCallback(mescroll) {
      // console.log("downCallback", page.num);
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getHotelScenicList(page, this.keyWords, this.pageCity)
        .then((data) => {
          // console.log("data-----", data);
          this.dataList = data.products || [];
          mescroll.endSuccess(this.dataList.length);
        })
        .catch(() => {
          mescroll.endErr();
        });
    },
    //上拉
    upCallback(page, mescroll) {
      // console.log(page.num);
      // console.log("upCallback", page.num);
      this.getHotelScenicList(page, this.keyWords, this.pageCity)
        .then((data) => {
          if (data.products.length != 0) {
            if (page.num > 0) {
              console.log("upCallback", data);
              let arr = data.products || [];
              this.dataList = this.dataList.concat(arr);
            } else {
              console.log("upCallback2", data);
              this.dataList = data.products || [];
            }
            this.$nextTick(() => {
              mescroll.endSuccess(data.products.length);
            });
          }
        })
        .catch(() => {
          mescroll.endErr();
        });
    },
    changeSearch(cityData) {
      this.pageCity = cityData;
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getHotelScenicList(page, this.keyWords, this.pageCity).then(
        (data) => {
          console.log("data", data);
          this.dataList = data.products || [];
        }
      );
      // console.log(value);
      // this.keyWords = value;
      // if (value) {
      //   this.getHotelScenicList(value, 0).then((data) => {
      //     console.log("data-----", data);
      //     this.dataList = data.products;
      //   });
      //   this.keyWords = value;
      // } else {
      //   this.getHotelScenicList("", 0).then((data) => {
      //     console.log("data-----", data);
      //     this.dataList = data.products;
      //   });
      //   this.keyWords = "";
      // }
    },
    // 获取酒景列表
    getHotelScenicList(page, content, pageCity) {
      console.log("----------------------", pageCity.cityName, page, content);
      let requestObj = {
        pageNo: page.num,
        pageSize: 20,
        content: content,
        arrCityCode: pageCity.cityCode,
        // arrCityCode: "440100000000",
      };
      return service.r215045(requestObj).then((r) => get(r, "res.bd.data"));
    },
    inputSearch(content) {
      this.keyWords = content;
      let page = {
        num: 1, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
        size: 20, //每页数据条数,默认10
      };
      this.getHotelScenicList(page, this.keyWords, this.pageCity).then(
        (data) => {
          // console.log("data-----", data);
          this.dataList = data.products;
        }
      );

      // console.log("content", content);
    },
  },
  created() {
    this.downCallback(this.mescroll);

    // this.isloading = true;
    // this.getHotelScenicList("", 1).then((data) => {
    //   console.log("data-----", data);
    //   this.dataList = data.products;
    //   this.isloading = false;
    // });
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/_vars.scss";
@import "./common.scss";
.mescroll {
  position: fixed;
  top: calc(56px + constant(safe-area-inset-top));
  top: calc(56px + env(safe-area-inset-top));
  // top: 0;
  bottom: 0;
  height: auto;
  background-color: #f2f4f7;
}
.mescrollAndriod {
  position: fixed;
  top: 88px;
  width: 375 * $px;
  top: calc(88px + constant(safe-area-inset-top));
  top: calc(88px + env(safe-area-inset-top));
  bottom: 0;
  height: auto;
  background-color: #f2f4f7;
}
.hotel-scenic-list {
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
  //   position: relative;
  .radiusWrap {
    // position: absolute;
    // left: 0;
    // top: calc(52px + constant(safe-area-inset-top));
    // top: calc(52px + env(safe-area-inset-top));
    width: 375 * $px;
    height: 12 * $px;
    background-color: #1799ff;
    z-index: -1;
    .radius {
      width: 375 * $px;
      height: 12 * $px;
      border-radius: 12px 12px 0px 0px;
      background: #ffffff;
    }
  }
}
.contain {
  padding: 0 12 * $px;
  .item {
    margin-bottom: 8 * $px;
  }
}
</style>
