<template>
  <div class="hotel-scenic-card">
    <div class="picture">
      <img class="picture-img" :src="dataItem.productImageUrl" alt="" />
      <!-- <div class="swiper">
        <div class="swiper-box">

          <swiper class="swiper" :show-indicators="false" :loop="false">
            <swiper-item v-for="(item, index) in dataLsit.productImageUrl" :key="index">
              <img :src="item.url" alt="" style="height: 100%; width: 100%;" />
              <div class="swipe-more fx-row fx-center" v-if="dataLsit.productImageUrl.length > 4 &&index===imgShowArr.length - 1"
                @click="goToPhotoAlbum">
                <i></i>
                <span>查看更多</span>
              </div>
              <div class="nums" @click="goToPhotoAlbum">{{index+1}}/{{ dataLsit.productImageUrl.length }}</div>
            </swiper-item>
          </swiper>
        </div>

      <div class="toBackImg" @click="backToList"></div>
        <img class="toBackImg" @click="backToList" src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/detail-back@3x.png" />
    </div> -->
    </div>
    <div class="title">{{ dataItem.productName }}</div>
    <div class="tags flex">
      <div class="tagsList flex" v-for="(item, index) in dataItem.tags" :key="index + item.name">
        <div class="tas-wrap" v-if="index != 0"></div>
        <div class="tags-item" :style="txtColour(item.colour)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="itemList">
      <div class="itemList-item flex" v-for="(item, index) in dataItem.itemList" :key="index + item.itemType">
        <img class="itemList-itemImg" :src="item.icon" alt="" />
        <div class="itemList-itemTxt">
          {{ item.itemName }}·{{ item.itemDesc }}
        </div>
      </div>
    </div>
    <div class="footer flex flex-between">
      <div class="productTags flex">
        <div class="productTags-item" v-for="(item, index) in dataItem.productTags" :key="index + item.name" :style="txtColour(item.colour)">
          {{ item.name }}
        </div>
      </div>
      <div class="price">
        <span class="txt">¥</span>{{ dataItem.price
        }}<span class="txt">起</span>
      </div>
    </div>
  </div>
</template>

<script>
import { px2rem } from "../mixins";
// import { Swiper, SwiperItem } from "huoli-ui";

export default {
  mixins: [px2rem],
  components: {
    // Swiper,
    // SwiperItem,
  },
  props: {
    dataItem: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "hotelScenicCard",
  data() {
    return {
      selectIndex: 0,
    };
  },
  computed: {},
  methods: {
    // goToPhotoAlbum() {},
    txtColour(colour) {
      return {
        color: `rgba(${colour})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
@import "../common.scss";
.hotel-scenic-card {
  width: 351 * $px;
  padding-bottom: 12 * $px;
  background: #f7f9fc;
  border-radius: 8px;
  position: relative;

  .picture {
    width: 351 * $px;
    height: 162 * $px;
    margin-bottom: 12 * $px;
    &-img {
      width: 351 * $px;
      height: 162 * $px;
      border-radius: 8px;
      object-fit: cover;
    }
    .swiper {
      position: relative;
      // .swipe-more {
      //   box-sizing: border-box;
      //   width: 48 * $px;
      //   height: 242 * $px;
      //   background: #f6f6f6;
      //   position: absolute;
      //   top: 0;
      //   right: 0;

      //   span {
      //     width: 12 * $px;
      //     height: 60 * $px;
      //     font-size: 12px;
      //     color: #262626;
      //     line-height: 15 * $px;
      //   }

      //   i {
      //     display: inline-block;
      //     width: 16 * $px;
      //     height: 16 * $px;
      //     background-color: transparent;
      //     background-repeat: no-repeat;
      //     background-position: center;
      //     background-size: 100% 100%;
      //     margin-right: 6 * $px;
      //     transform: translate3d(0, 0px, 0);
      //     background-image: url("https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/left-arrow@3x.png");
      //   }
      // }

      .swiper-box {
        position: relative;
        z-index: 1;
        .swiper {
          width: 351 * $px;
          height: 162 * $px;
        }
      }

      .nums {
        position: absolute;
        right: 12 * $px;
        bottom: 12 * $px;
        padding: 4 * $px 8 * $px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 11 * $px;
        font-size: 12px;
        color: #ffffff;
        line-height: 17 * $px;
        z-index: 2;
      }

      .toBackImg {
        width: 28 * $px;
        height: 28 * $px;
        position: absolute;
        top: 52 * $px;
        left: 12 * $px;
        z-index: 2;
      }
    }
  }
  .title {
    height: 21 * $px;
    font-size: 15 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #262626;
    margin-bottom: 4 * $px;
    margin-left: 12 * $px;
  }
  .tags {
    margin-left: 4 * $px;
    margin-bottom: 12 * $px;
    .tagsList {
      align-items: center;

      .tas-wrap {
        width: 1 * $px;
        height: 8 * $px;
        background: rgba(52, 56, 75, 0.75);
      }
      .tags-item {
        padding: 0 8 * $px;
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        // color: rgba(52, 56, 75, 0.75);
      }
    }
  }
  .itemList {
    margin-left: 12 * $px;
    margin-bottom: 12 * $px;
    .itemList-item {
      margin-bottom: 4 * $px;
      align-items: center;
      height: 16 * $px;
      .itemList-itemImg {
        width: 16 * $px;
        height: 16 * $px;
        margin-right: 8 * $px;
        // background-color: blue;
      }
      .itemList-itemTxt {
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(52, 56, 75, 0.75);
        padding-bottom: 1px;
      }
    }
  }
  .footer {
    height: 25 * $px;
    margin-left: 12 * $px;
    align-items: center;
    margin-right: 12 * $px;
    .productTags {
      overflow: hidden;
      .productTags-item {
        // height: 16px;
        // padding: 0 4 * $px;
        // border: 1 * $px solid rgba(0, 120, 255, 0.5);
        // border-radius: 4 * $px;
        font-size: 11 * $px;
        // font-family: PingFangSC, PingFangSC-Regular;
        // font-weight: 400;
        color: #0078ff;
        // margin-right: 4 * $px;
        height: 16 * $px;
        border: 1px solid rgba(0, 120, 255, 0.5);
        border-radius: 4 * $px;
        padding: 0 3 * $px;
        margin-right: 4 * $px;
        margin-top: 4 * $px;
        flex-shrink: 0;
      }
    }
    .price {
      margin-left: 4 * $px;
      // position: absolute;
      // right: 12 * $px;
      // bottom: 12 * $px;
      flex-shrink: 0;
      font-size: 18 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ff6814;
      .txt {
        font-size: 12 * $px;
      }
    }
  }
}
</style>
